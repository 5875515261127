import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import ArrowIcon from '../../../../assets/icons/ArrowIcon';
import { IInputDropdownProps } from './InputDropdown.types';
import InputArea from '../InputArea/InputArea';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import useMobile from '../../../../hooks/useIsMobile';
import { getVariation } from '../../../../functions/functions';

const InputDropdown: FC<IInputDropdownProps> = ({
  options,
  isPositionedOnTop = false,
  isPositionDynamic = false,
  placeholder,
  value,
  error,
  isDisabled,
  variant,
  additionalIcon,
  isTranslation,
  isSearchFilter,
  isHourInput,
  parentRef,
  initialValueReload,
}) => {
  const initialValue = isTranslation
    ? options.find((item) => item.value === value)?.label || ''
    : value || options[0]?.label || '';

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shownValue, setShownValue] = useState<string>(initialValue);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isBottomEdgeCollision, setIsBottomEdgeCollision] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const isMobile = useMobile();

  const handleToggleOpen = () => {
    if (!isDisabled) setIsOpen(!isOpen);
  };

  const handleSearchInputChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  const handleOptionClick = (callback: () => void, label: string) => {
    callback();
    setIsOpen(false);
    setShownValue(label);
  };

  useDetectOutsideClick(wrapperRef, handleCloseDropdown);

  const checkDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();

      if (parentRef?.current) {
        const rect2 = parentRef.current.getBoundingClientRect();
        if (rect.bottom > rect2.bottom) {
          setIsBottomEdgeCollision(true);
        } else {
          setIsBottomEdgeCollision(false);
        }
      } else if (rect.bottom > window.innerHeight) {
        setIsBottomEdgeCollision(true);
      } else {
        setIsBottomEdgeCollision(false);
      }
    }
  };

  useEffect(() => {
    if (value !== undefined && !isTranslation) {
      setShownValue(value);
    } else if (value === undefined && isHourInput) {
      setShownValue('');
    }
  }, [value, isTranslation, isHourInput]);

  useEffect(() => {
    if (initialValueReload) setShownValue(initialValue);
  }, [initialValue, initialValueReload]);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase()),
  );

  useLayoutEffect(() => {
    if (isOpen) {
      checkDropdownPosition();
      window.addEventListener('resize', checkDropdownPosition);

      return () => {
        window.removeEventListener('resize', checkDropdownPosition);
      };
    }
    setIsBottomEdgeCollision(false);
  }, [isOpen]);

  return (
    <>
      <div
        ref={wrapperRef}
        className={clsx(
          'relative flex items-center justify-center rounded-xs border-[1px] border-solid',
          error && 'border-red-600',
          isDisabled && 'pointer-events: none',
          (variant === 'modal' || variant === 'fixedToTop') &&
            'h-[42px] md:min-w-[340px]',
          variant === 'half-modal' && 'h-[42px] md:min-w-[300px]',
          variant === 'color' && 'h-[42px] md:min-w-[86px]',
          variant === 'recurrence' && 'h-[42px] md:w-[160px]',
          variant === 'list' && 'h-[42px] flex-1 md:min-w-[340px]',
          isOpen ? 'border-white' : 'border-brand-700',
        )}
      >
        {additionalIcon ? <span className="pl-4">{additionalIcon}</span> : null}
        <button
          type="button"
          className={clsx(
            'flex gap-2.5 px-4 py-3',
            (variant === 'modal' ||
              variant === 'half-modal' ||
              variant === 'recurrence' ||
              variant === 'list' ||
              variant === 'fixedToTop') &&
              'w-full justify-between',
            isDisabled ? 'cursor-default text-brand-1200' : 'text-white',
            variant === 'pagination' && 'min-w-[86px]',
          )}
          onClick={handleToggleOpen}
        >
          {variant === 'color' ? (
            <span
              className="h-6 w-6 rounded-[4px]"
              style={{ backgroundColor: shownValue }}
            />
          ) : (
            <span
              className={clsx(
                'line-clamp-1',
                shownValue && shownValue.length > 0
                  ? 'text-white'
                  : 'text-brand-1700',
                isDisabled && '!text-brand-1200',
              )}
            >
              {shownValue && shownValue.length > 0 ? shownValue : placeholder}
            </span>
          )}
          <span
            className={clsx(
              ' duration-100 ease-in-out',
              isOpen ? 'rotate-0' : 'rotate-180',
            )}
          >
            <ArrowIcon isDisabled={isDisabled} />
          </span>
        </button>
        {isOpen ? (
          <div
            ref={dropdownRef}
            className={clsx(
              'absolute z-10 flex w-full flex-col gap-4 rounded-xs border bg-brand-50 p-5',
              variant === 'pagination' &&
                (isPositionedOnTop ? 'top-[-224px]' : 'bottom-[-224px]'),
              (variant === 'modal' ||
                variant === 'half-modal' ||
                variant === 'recurrence' ||
                variant === 'list') &&
                isBottomEdgeCollision &&
                isPositionDynamic &&
                'top-[-268px] max-h-[234px]',
              (variant === 'modal' ||
                variant === 'half-modal' ||
                variant === 'recurrence' ||
                variant === 'list') &&
                (!isBottomEdgeCollision || !isPositionDynamic) &&
                'top-[64px] max-h-[234px]',
              variant === 'color' && 'right-0 w-full',
              variant === 'color' && isBottomEdgeCollision && 'bottom-[48px]',
              variant === 'color' && !isBottomEdgeCollision && 'top-[48px]',
              variant === 'color' &&
                isMobile &&
                'min-w-[180px] xs:min-w-[320px]',
              variant === 'color' && !isMobile && 'min-w-[498px]',
              variant === 'fixedToTop' && 'top-12 z-[500] max-h-[150px]',
            )}
          >
            {isSearchFilter && (
              <div className="static top-0">
                <InputArea
                  name="search"
                  type="text"
                  placeholder={t('messages.searchPlaceholder')}
                  value={searchValue}
                  variant="secondary"
                  onChange={handleSearchInputChange}
                  isError={false}
                  isDisabled={false}
                  maxLength={50}
                />
              </div>
            )}
            <div
              className={clsx(
                'custom-scrollbar scrolling flex',
                variant === 'pagination' && 'overflow-hidden',
                variant === 'color'
                  ? 'flex-row flex-wrap items-center gap-3'
                  : 'flex-col gap-4 overflow-y-auto overflow-x-hidden',
              )}
            >
              {filteredOptions.map((option) =>
                variant === 'color' ? (
                  <span
                    key={option.label}
                    className={clsx(
                      'h-6 w-6 cursor-pointer rounded-[4px]',
                      shownValue === option.label && 'border-2 border-white',
                    )}
                    style={{ backgroundColor: option.label }}
                    onClick={() =>
                      handleOptionClick(option.onClick, option.label)
                    }
                  />
                ) : (
                  <button
                    key={option.label + (option.spacePlan || '')}
                    type="button"
                    className="flex items-center justify-between gap-2"
                    onClick={() =>
                      handleOptionClick(option.onClick, option.label)
                    }
                  >
                    <span
                      className={clsx(
                        'flex gap-1',
                        isMobile ? 'min-w-[120px]' : 'min-w-[150px]',
                        variant === 'recurrence' && 'min-w-[90px]',
                      )}
                    >
                      <span className="truncate">
                        {!isMobile && option.seats && `${t('messages.table')} `}
                        {option.label}
                      </span>
                      {option.seats && (
                        <span className="text-brand-1700">
                          (
                          {`${option.seats} ${getVariation(
                            option.seats,
                            t('messages.seat'),
                            t('messages.seats'),
                            t('messages.seatsOther'),
                          )}`}
                          )
                        </span>
                      )}
                    </span>
                    {option.spacePlan && (
                      <span className="whitespace-break-spaces pr-2">
                        {option.spacePlan}
                      </span>
                    )}
                  </button>
                ),
              )}
            </div>
          </div>
        ) : null}
      </div>
      {!!error && (
        <div
          className={clsx(variant === 'half-modal' ? 'mt-[2px]' : '-mt-[6px]')}
        >
          <InputErrorMessage error={error} />
        </div>
      )}
    </>
  );
};

export default InputDropdown;
