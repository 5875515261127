import {
  addMinutes,
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  getDay,
  getDaysInMonth,
  getMonth,
  getYear,
  parse,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { t } from 'i18next';
import { Timestamp } from 'firebase/firestore';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { getAuth } from 'firebase/auth';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ChangeEvent } from 'react';

import i18n from '../configuration/i18n';
import { IScheduleAvailabilityWithStringHours } from '../types/ISchedule';
import { IOpeningHours, IOpeningHoursSchedule } from '../types/FirstSteps';
import { TOpeningHours, TPlace } from '../types/Place';
import { TReservationStatistics, TStatistics } from '../types/Statistics';
import {
  daysOfWeek,
  INITIAL_TIMEZONE,
  MONTHLY_RECCURENCE_OPTIONS,
  MONTHS,
  RECURRENCE_REPEAT_OPTIONS,
  weekDays,
} from '../constants/constants';
import { IHourSlot } from '../types/Reservation';
import { IOpeningHourSpecialDay, TDayOfWeek } from '../types/SpecialDay';
import { IOption } from '../components/UI/organisms/ReservationsPanel/ReservationsPanel.types';
import { errorSchema } from '../schema/ErrorSchemas';

export const firebaseErrorTemplate = (input: string) =>
  `Firebase: Error (${input}).`;

export const handleCommonAuthErrors = (error: Error) => {
  switch (error.message) {
    case firebaseErrorTemplate('auth/wrong-password'):
      return 'errorMessages.incorrectLoginOrPasswordError';
    case firebaseErrorTemplate('auth/user-not-found'):
      return 'errorMessages.incorrectLoginOrPasswordError';
    case firebaseErrorTemplate('auth/invalid-user-token'):
      return 'errorMessages.invalidUserToken';
    case firebaseErrorTemplate('auth/network-request-failed'):
      return 'errorMessages.networkRequestFailed';
    case firebaseErrorTemplate('auth/too-many-requests'):
      return 'errorMessages.tooManyRequestsAuth';
    case firebaseErrorTemplate('auth/user-disabled'):
      return 'errorMessages.userDisabled';
    case firebaseErrorTemplate('auth/user-token-expired'):
      return 'errorMessages.userTokenExpired';
    case firebaseErrorTemplate('auth/web-storage-unsupported'):
      return 'errorMessages.webStorageUnsupported';
    case firebaseErrorTemplate('auth/invalid-login-credentials'):
      return 'errorMessages.incorrectLoginOrPasswordError';
    default:
      return 'errorMessages.unknownCommonError';
  }
};

export const handleForbiddenError = (
  error: unknown,
  defaultMessage?: string,
) => {
  if (error instanceof AxiosError) {
    const parsedError = errorSchema.safeParse(error.response?.data);

    if (!parsedError.success) {
      if (defaultMessage) {
        toast.error(defaultMessage);
      }
      return;
    }

    const { errorCode } = parsedError.data;

    if (errorCode === 'FORBIDDEN') {
      toast.error(t('errorMessages.forbidden'));
    }
  } else if (defaultMessage) {
    toast.error(defaultMessage);
  }
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileExtension = (file: File): string => {
  const fileName = file.name;
  const lastDot = fileName.lastIndexOf('.');
  const extension = `.${fileName.substring(lastDot + 1)}`;
  return extension;
};

export const getFormatedDate = (timestamp: number): string => {
  const formattedDate = format(new Date(timestamp * 1000), 'dd.MM.yyyy');
  return formattedDate;
};

export const getDateLabel = (
  dateString: any,
  includeYear: boolean = true,
  isTodayYesterday: boolean = true,
) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (isTodayYesterday) {
    if (date.toDateString() === today.toDateString()) {
      return i18n.t('messages.today');
    }
    if (date.toDateString() === yesterday.toDateString()) {
      return i18n.t('messages.yesterday');
    }
  }

  return date?.toLocaleDateString(i18n.language, {
    day: '2-digit',
    month: 'long',
    year: includeYear ? 'numeric' : undefined,
  });
};

export const getShortFormatDate = (date: Date) => {
  return date?.toLocaleDateString(i18n.language, {
    day: 'numeric',
    month: 'short',
    year: undefined,
  });
};

export const getLongFormatDate = (date: Date) => {
  const day = date.getDate();
  const month = date?.toLocaleDateString(i18n.language, {
    month: 'long',
  });
  const year = date?.getFullYear();

  return `${day} ${month} ${year}`;
};

export const timeToSeconds = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60;
};

export const formatPhoneNumber = (
  value: string | number,
  code: CountryCode,
) => {
  if (!value) return '';

  const asYouType = new AsYouType(code);

  value = value.toString();

  if (value.includes('(') && !value.includes(')')) {
    return value.replace('(', '');
  }

  asYouType.input(value);

  return asYouType.getNumber()?.number || value;
};

export const isFirebaseTimestamp = (obj: unknown): obj is Timestamp => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'seconds' in obj &&
    'nanoseconds' in obj &&
    'toDate' in obj &&
    typeof (obj as Timestamp).seconds === 'number' &&
    typeof (obj as Timestamp).nanoseconds === 'number' &&
    typeof (obj as Timestamp).toDate === 'function'
  );
};

export const timeStringToMinutes = (timeString: string) => {
  let totalMinutes = 0;

  const hourMatch = timeString.match(
    new RegExp(`(\\d+)\\s*${t('messages.hoursPlaceholder')}`),
  );
  const minuteMatch = timeString.match(
    new RegExp(`(\\d+)\\s*${t('messages.minutesPlaceholder')}`),
  );

  if (hourMatch && hourMatch[1]) {
    totalMinutes += parseInt(hourMatch[1], 10) * 60;
  }

  if (minuteMatch && minuteMatch[1]) {
    totalMinutes += parseInt(minuteMatch[1], 10);
  }

  return totalMinutes;
};

export const minutesToTimeString = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours} ${t('messages.hoursPlaceholder')} `;
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes} ${t('messages.minutesPlaceholder')}`;
  }

  if (!hours && !remainingMinutes) {
    result = `0 ${t('messages.minutesPlaceholder')}`;
  }

  return result.trim();
};

export const resetTimeInUTC = (
  date: Date,
  hours: number = 0,
  min: number = 0,
  sec: number = 0,
  ms: number = 0,
): Date => {
  date.setHours(hours, min, sec, ms);

  const offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000;
  date.setTime(date.getTime() - offsetInMilliseconds);

  return date;
};

export const adjustDateForUTC = (date: Date): Date => {
  const offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000;

  date.setTime(date.getTime() - offsetInMilliseconds);

  return date;
};

export const extractHourFromIsoString = (
  isoString: string,
  timeZone?: string | null,
): string => {
  const date = new Date(isoString);

  if (timeZone) {
    const zonedDate = utcToZonedTime(date, timeZone);
    return format(zonedDate, 'HH:mm');
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const combineDateAndTime = (
  date: Date,
  timeString: string,
  timeZone: string,
) => {
  // Create Date object based on "Europe/Warsaw" timezone
  const [hours, minutes] = timeString.split(':').map(Number);
  const zonedDate = utcToZonedTime(date, timeZone);
  zonedDate.setHours(hours, minutes, 0, 0);

  // Convert this date to UTC
  const timeInUTC = zonedTimeToUtc(zonedDate, timeZone);

  return timeInUTC;
};

export const convertLocalDateToAdjustedUTC = (date: Date) => {
  const offsetInHours = date.getTimezoneOffset() / -60;

  date.setHours(date.getHours() + offsetInHours);

  return date.toISOString();
};

export const extractHoursAndMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return { hours, minutes };
};

export const isAvailabilityWithinOpeningHours = (
  dayAvailabilities: IScheduleAvailabilityWithStringHours[],
  dayOfWeek: string,
  openingHours: IOpeningHoursSchedule,
) => {
  if (dayAvailabilities.length === 0) return true;
  if (!openingHours) return true;

  const dayOpeningHours = openingHours[dayOfWeek] || null;

  let isWithinOpeningHours = false;

  if (!dayOpeningHours) {
    return false;
  }

  dayOpeningHours.map((day) => {
    const { open, close } = day;

    const openTimeParts = open.split(':');
    const closeTimeParts = close.split(':');

    if (openTimeParts.length !== 2 || closeTimeParts.length !== 2) {
      return false;
    }

    const openTime = new Date();
    openTime.setHours(parseInt(openTimeParts[0], 10));
    openTime.setMinutes(parseInt(openTimeParts[1], 10));
    openTime.setSeconds(0);

    const closeTime = new Date();
    closeTime.setHours(parseInt(closeTimeParts[0], 10));
    closeTime.setMinutes(parseInt(closeTimeParts[1], 10));
    closeTime.setSeconds(0);

    dayAvailabilities.map(
      (availability: IScheduleAvailabilityWithStringHours) => {
        const {
          hours: availabilityStartHours,
          minutes: availabilityStartMinutes,
        } = extractHoursAndMinutes(availability.start);

        const { hours: availabilityEndHours, minutes: availabilityEndMinutes } =
          extractHoursAndMinutes(availability.end);

        const openHours = openTime.getHours();
        const openMinutes = openTime.getMinutes();

        const closeHours = closeTime.getHours();
        const closeMinutes = closeTime.getMinutes();

        if (
          (availabilityStartHours > openHours ||
            (availabilityStartHours === openHours &&
              availabilityStartMinutes >= openMinutes)) &&
          (availabilityEndHours < closeHours ||
            (availabilityEndHours === closeHours &&
              availabilityEndMinutes <= closeMinutes))
        ) {
          isWithinOpeningHours = true;
        }
      },
    );
  });

  return isWithinOpeningHours;
};

export const getVariation = (
  number: number,
  singular: string,
  plural: string,
  pluralGenitive: string,
): string => {
  if (number === 1) return singular;
  const modulo10 = number % 10;
  const modulo100 = number % 100;
  if (modulo10 > 4 || modulo10 < 2 || (modulo10 < 15 && modulo100 > 11)) {
    return pluralGenitive;
  }
  return plural;
};

export const getActionTranslation = (actionNeededType: string[]) => {
  if (
    actionNeededType?.includes('HOURS_COLLISION') &&
    actionNeededType?.includes('SEATS_COUNT_COLLISION')
  ) {
    return i18n.t('messages.collidingBothHoursAndTableReservationTooltip');
  }
  if (actionNeededType?.includes('HOURS_COLLISION')) {
    return i18n.t('messages.collidingHoursReservationTooltip');
  }
  if (actionNeededType?.includes('SEATS_COUNT_COLLISION')) {
    return i18n.t('messages.collidingTableReservationTooltip');
  }
  if (actionNeededType?.includes('SPECIAL_DAY_ERROR')) {
    return i18n.t('messages.collidingSpecialDayReservationTooltip');
  }
  if (actionNeededType?.includes('AVAILABILITY_COLLISION')) {
    return i18n.t('messages.collidingAvailabilityReservationTooltip');
  }
  return '';
};

export const formatStringHour = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const timeStringToDate = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

export const stringHourToMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
};

export const getZonedTimeFromIsoDate = (
  isoDate: string,
  timezone: string | null | undefined = INITIAL_TIMEZONE,
) => {
  const date = parseISO(isoDate);
  const validTimezone =
    typeof timezone === 'string' ? timezone : INITIAL_TIMEZONE;

  return utcToZonedTime(date, validTimezone);
};

export const getZonedTimeFromIsoDateAndFormat = (
  isoDate: string,
  timezone: string | null | undefined = INITIAL_TIMEZONE,
) => {
  const date = parseISO(isoDate);

  const validTimezone =
    typeof timezone === 'string' ? timezone : INITIAL_TIMEZONE;

  const zonedDate = utcToZonedTime(date, validTimezone);
  return format(zonedDate, 'dd.MM.yyyy');
};

export const convertDateIntoUtcDate = (date: Date, timezone: string) => {
  const zonedDate = utcToZonedTime(date, timezone);
  zonedDate.setHours(0, 0, 0, 0);
  const timeInUTC = zonedTimeToUtc(zonedDate, timezone);
  return timeInUTC;
};

export const checkIfWithinOpeningHours = (
  currentStart: string,
  currentEnd: string,
  openingHours: IOpeningHoursSchedule,
) => {
  const isTimeInRange = (
    startTime: string,
    endTime: string,
    checkTime: string,
  ) => {
    return checkTime >= startTime && checkTime <= endTime;
  };

  let isWithinOpeningHours = false;

  Object.values(openingHours).forEach((day) => {
    day.map((item) => {
      const dayOpen = item.open;
      const dayClose = item.close;
      if (
        isTimeInRange(dayOpen, dayClose, currentStart) &&
        isTimeInRange(dayOpen, dayClose, currentEnd)
      ) {
        isWithinOpeningHours = true;
      }
    });
  });

  return isWithinOpeningHours;
};

export const convertOpeningHoursForSpecialDaySchedule = (
  open: string,
  close: string,
  date: string,
): IOpeningHourSpecialDay => {
  const openingTimes: IOpeningHourSpecialDay = {};
  const parsedDate = parseISO(date);
  const dayOfWeekIndex = getDay(parsedDate);
  const dayOfWeek: TDayOfWeek = weekDays[dayOfWeekIndex];

  const openMinutes = stringHourToMinutes(open);
  const closeMinutes = stringHourToMinutes(close);

  if (!openingTimes[dayOfWeek]) {
    openingTimes[dayOfWeek] = [];
  }

  if (openMinutes < closeMinutes || closeMinutes === 0) {
    openingTimes[dayOfWeek]?.push({ open, close });
  } else {
    openingTimes[dayOfWeek]?.push({ open, close: '23:59' });
    const nextDay: TDayOfWeek = weekDays[(dayOfWeekIndex + 1) % 7];
    if (!openingTimes[nextDay]) {
      openingTimes[nextDay] = [];
    }
    openingTimes[nextDay]?.push({ open: '00:00', close });
  }

  return openingTimes;
};

export const convertOpeningHoursForSchedule = (openingHours: IOpeningHours) => {
  const daysOfWeekValues: (keyof IOpeningHours)[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const result = {} as IOpeningHoursSchedule;

  daysOfWeekValues.forEach((day, index) => {
    const currentDay = openingHours[day];
    const prevDay = openingHours[daysOfWeekValues[(index - 1 + 7) % 7]];

    const openingTimes = [];

    if (currentDay) {
      if (
        stringHourToMinutes(currentDay.open) <
        stringHourToMinutes(currentDay.close)
      ) {
        openingTimes.push({ open: currentDay.open, close: currentDay.close });
      } else {
        openingTimes.push({ open: currentDay.open, close: '23:59' });
      }
    }

    if (prevDay) {
      if (
        stringHourToMinutes(prevDay.open) > stringHourToMinutes(prevDay.close)
      ) {
        openingTimes.push({ open: '00:00', close: prevDay.close });
      }
    }

    result[day] = openingTimes;
  });

  return result;
};

export const displaySystemNotification = (notificationText: string) => {
  // TODO: Adapt when refactoring notification logic
  // if (!('Notification' in window)) {
  //   // Notification API is not supported in this browser.
  // } else if (Notification.permission === 'granted') {
  //   const notification = new Notification(notificationText);
  // }
};

export const getMinAndMaxDate = (placeValue: TPlace | null) => {
  // TODO adapt when making seasoned places
  // let minDate: Date | undefined = today;
  // let maxDate: Date | undefined;

  // if (placeValue?.isSeasoned) {
  //   const seasonedDateStart = placeValue.seasonedDateStart
  //     ? convertTimestampToDate(placeValue.seasonedDateStart)
  //     : undefined;
  //   const seasonedDateEnd = placeValue.seasonedDateEnd
  //     ? convertTimestampToDate(placeValue.seasonedDateEnd)
  //     : undefined;

  //   minDate =
  //     seasonedDateStart && isAfter(today, seasonedDateStart)
  //       ? today
  //       : seasonedDateStart;

  //   maxDate = seasonedDateEnd;
  // }

  // return { minDate, maxDate };
  return { minDate: new Date(), maxDate: new Date() }; // TODO delete when adapting function
};

export const addCurrentTimeToDateIfToday = (date: Date) => {
  const now = new Date();
  const inputDate = new Date(date);

  if (
    now.getFullYear() === inputDate.getFullYear() &&
    now.getMonth() === inputDate.getMonth() &&
    now.getDate() === inputDate.getDate()
  ) {
    inputDate.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds(),
    );
  }

  return inputDate;
};

export const fillMissingDates = <
  T extends TStatistics | TReservationStatistics,
>(
  startDate: string | Date,
  endDate: string | Date,
  data: T[],
  isReservation: boolean = false,
): T[] => {
  const start = new Date(new Date(startDate).setHours(23, 59, 59, 999));
  const end = new Date(new Date(endDate).setHours(23, 59, 59, 999));
  const dateRange: T[] = [];

  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const dateFormatted = d.toISOString().split('T')[0];
    const item = data.find((i) => i.date === dateFormatted) as T;

    if (!isReservation) {
      dateRange.push(item || ({ date: dateFormatted, count: 0 } as T));
    } else {
      dateRange.push(
        item ||
          ({
            date: dateFormatted,
            countFunzy: 0,
            countGoogle: 0,
          } as T),
      );
    }
  }

  return dateRange;
};

export const getStatisticsTotalCount = (
  data: { date: string; count: number }[],
) => {
  const total = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);
  return total;
};

export const getTokenFromFirebaseAuth = async () => {
  const user = getAuth().currentUser;
  if (user) {
    const token = await user.getIdToken(true);
    return token;
  }
};

export const deepEqual = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) return false;

  return array1.every((value, index) => value === array2[index]);
};

export const calculateDurationInMinutes = (
  dateStart: string,
  dateEnd: string,
): number => {
  const startDate = new Date(dateStart);
  const endDate = new Date(dateEnd);

  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

  const diffInMinutes = differenceInMilliseconds / 1000 / 60;

  return diffInMinutes;
};

export const parseTime = (timeStr: string) => {
  const [hoursStr, minutesStr] = timeStr.split(':');
  const hours = hoursStr ? Number(hoursStr) : 0;
  const minutes = minutesStr ? Number(minutesStr) : 0;
  return hours * 60 + minutes;
};

export const generateSlots = (
  inputSlots: IHourSlot[],
  interval: number,
  timeRange: { open: string; close: string },
  previous: boolean,
): IHourSlot[] => {
  const resultSlots: IHourSlot[] = [];
  const [openHour = 0, openMinute = 0] = timeRange.open.split(':').map(Number);
  let [closeHour = 0, closeMinute = 0] = timeRange.close.split(':').map(Number);
  let currentTime = openHour * 60 + openMinute;

  if (
    closeHour < openHour ||
    (closeHour === openHour && closeMinute <= openMinute)
  ) {
    closeHour = 24;
    closeMinute = 0;
  }

  const endTime = closeHour * 60 + closeMinute;
  const inputSlotMap = new Map(inputSlots.map((slot) => [slot.slot, slot]));

  while (currentTime < endTime) {
    const hours = Math.floor(currentTime / 60);
    const minutes = currentTime % 60;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;

    const existingSlot = inputSlotMap.get(formattedTime);

    resultSlots.push(
      existingSlot
        ? { ...existingSlot, previous }
        : { slot: formattedTime, avaId: null, free: null, previous },
    );

    currentTime += interval;
  }

  return resultSlots;
};

export const checkReservationPossibility = (
  slots: IHourSlot[],
  numPeople: number,
  durationMinutes: number,
  slotInterval: number,
  reservationDate: Date,
  isWalkIn: boolean,
  nextDayAvailability: IHourSlot[],
  openingHour: string | undefined,
  timezone: string,
): IHourSlot[] => {
  const formatTime = (totalMinutes: number): string => {
    const hours = Math.floor(totalMinutes / 60) % 24;
    const minutes = (totalMinutes % 60).toString().padStart(2, '0');
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const now = utcToZonedTime(new Date(), timezone);
  let currentTime = now.getHours() * 60 + now.getMinutes();

  if (!isWalkIn) {
    currentTime += 15;
  }

  const isToday = reservationDate.toDateString() === now.toDateString();

  const slotsMap = slots.reduce(
    (map, slot) => {
      map[slot.slot] = slot;
      return map;
    },
    {} as { [key: string]: IHourSlot },
  );

  const nextDaySlotsMap = nextDayAvailability.reduce(
    (map, slot) => {
      map[slot.slot] = slot;
      return map;
    },
    {} as { [key: string]: IHourSlot },
  );

  const availableSlots: IHourSlot[] = [];

  slots
    .filter((slot) => slot.avaId)
    .forEach(({ slot: startTime, avaId, free, previous }) => {
      let currentTimeSlot = parseTime(startTime);
      const endTime = currentTimeSlot + durationMinutes;
      let canReserve = true;

      while (currentTimeSlot < endTime) {
        const currentSlot = formatTime(currentTimeSlot);

        const slotData = slotsMap[currentSlot];
        const nextDaySlotData = nextDaySlotsMap[currentSlot];

        const isSlotAvailableInCurrentDay =
          slotData &&
          previous === slotData.previous &&
          ((slotData.free != null && slotData.free >= numPeople) ||
            slotData.free == null);

        const startTimeMinutes = parseTime(startTime);
        const openingHourMinutes = openingHour ? parseTime(openingHour) : 0;

        const isSlotAvailableInNextDay =
          nextDaySlotData &&
          ((nextDaySlotData.free != null &&
            nextDaySlotData.free >= numPeople) ||
            nextDaySlotData.free == null) &&
          startTimeMinutes > openingHourMinutes;

        const isCurrentSlotAvailable =
          isSlotAvailableInCurrentDay || isSlotAvailableInNextDay;

        if (!isCurrentSlotAvailable) {
          canReserve = false;
          break;
        }

        currentTimeSlot += slotInterval;
      }

      const startTimeMinutes = parseTime(startTime);

      let previousSlotMinutes =
        Math.floor(currentTime / slotInterval) * slotInterval;

      if (isWalkIn && isToday) {
        if (startTimeMinutes < currentTime) {
          previousSlotMinutes =
            Math.floor((currentTime - 1) / slotInterval) * slotInterval;
        }
      }

      const shouldAddPastSlot =
        isWalkIn && isToday && startTimeMinutes === previousSlotMinutes;

      if (
        canReserve &&
        ((isToday && (startTimeMinutes >= currentTime || shouldAddPastSlot)) ||
          !isToday ||
          startTimeMinutes >= currentTime)
      ) {
        availableSlots.push({ slot: startTime, avaId, free });
      }
    });

  availableSlots.sort((a, b) => parseTime(a.slot) - parseTime(b.slot));

  return isWalkIn ? availableSlots.slice(0, 3) : availableSlots;
};

export const convertToISODateString = (
  hour: string,
  date: Date,
  timezone: string,
) => {
  const dateString = format(date, 'yyyy-MM-dd');
  const dateTimeString = `${dateString} ${hour}:00`;

  const localDateTime = parse(
    dateTimeString,
    'yyyy-MM-dd HH:mm:ss',
    new Date(),
  );

  const utcDateTime = zonedTimeToUtc(localDateTime, timezone);

  return utcDateTime.toISOString();
};

export const addMinutesToISODateString = (
  isoDateString: string,
  minutes: number,
) => {
  const date = parseISO(isoDateString);
  const newDate = addMinutes(date, minutes);
  return newDate.toISOString();
};

export const getDaysToDisplayInSchedule = (daysToShow: number | number[]) => {
  if (Array.isArray(daysToShow)) {
    return daysOfWeek
      .filter((day) => daysToShow.includes(day.value))
      .sort(
        (a, b) => daysToShow.indexOf(a.value) - daysToShow.indexOf(b.value),
      );
  }

  const validNumberOfDays = Math.min(Math.max(daysToShow, 1), 7);
  return daysOfWeek.slice(0, validNumberOfDays);
};

export const addMinutesToTime = (timeString: string, minutesToAdd: number) => {
  const [hours, minutes] = timeString.split(':').map(Number);

  const date = new Date();
  date.setHours(hours, minutes, 0, 0);

  date.setMinutes(date.getMinutes() + minutesToAdd);

  const newHours = String(date.getHours()).padStart(2, '0');
  const newMinutes = String(date.getMinutes()).padStart(2, '0');

  return `${newHours}:${newMinutes}`;
};

export const getDifferenceInMinutes = (
  date1ISOString: string,
  date2ISOString: string,
) => {
  const date1 = new Date(date1ISOString);
  const date2 = new Date(date2ISOString);

  return differenceInMinutes(date2, date1);
};

export const sortByName = <T extends { name: string }>(array: T[]): T[] => {
  return array.sort((a, b) => a.name.localeCompare(b.name));
};

export const sortByTranslatedName = (
  options: IOption[],
  translation: (key: string) => string,
): IOption[] => {
  return options.slice().sort((a, b) => {
    const nameA = translation(`messages.${a.name}`);
    const nameB = translation(`messages.${b.name}`);
    return nameA.localeCompare(nameB);
  });
};

export const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome');
};

export const getDayOfWeek = (dateString: string): (typeof weekDays)[number] => {
  type DayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
  const date = new Date(dateString);
  const dayNum = date.getDay() as DayNumber;
  return weekDays[dayNum];
};

export const isValidTime = (time: string | Date) => {
  if (time instanceof Date) {
    return !Number.isNaN(time.getTime());
  }
};

export const periodMapping: Record<string, string> = {
  day: 'daily',
  daily: 'daily',
  custom: 'daily',
  week: 'weekly',
  weekly: 'weekly',
  everyWeekday: 'weekly',
  month: 'monthly',
  monthly: 'monthly',
  year: 'yearly',
  yearly: 'yearly',
};

export const areArraysEqual = (arr1: number[], arr2: number[]): boolean =>
  arr1.length === arr2.length &&
  arr1.every((val, index) => val === arr2[index]);

export const calculateDaysInMonth = (
  month: number,
  year: number = new Date().getFullYear(),
): number => {
  return getDaysInMonth(new Date(year, month, 1));
};

export const handleDateFromYearAndMonth = (
  watchedStartDate: string | Date,
  watchedMonth?: string,
): Date => {
  const selectedYear = getYear(new Date(watchedStartDate));

  const selectedMonthIndex = watchedMonth ? MONTHS.indexOf(watchedMonth) : 0;
  const monthIndex = selectedMonthIndex === -1 ? 0 : selectedMonthIndex;

  return new Date(selectedYear, monthIndex, 1);
};

export const handleDayOfMonthChange = (
  e: ChangeEvent<HTMLInputElement>,
  onChange: (value: string) => void,
  selectedDate: Date | string,
): void => {
  let newValue = e.target.value.replace(/\D/g, '');

  const date = new Date(selectedDate);
  const selectedMonth = getMonth(date);
  const selectedYear = getYear(date);

  const maxDaysInMonth = getDaysInMonth(new Date(selectedYear, selectedMonth));

  if (newValue !== '' && Number(newValue) > maxDaysInMonth) {
    newValue = maxDaysInMonth.toString();
  }

  onChange(newValue);
};
