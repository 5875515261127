import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import AvailabilityBox from '../../atoms/AvailabilityBox/AvailabilityBox';
import WorkspaceAvailabilityBox from '../../atoms/WorkspaceAvailabilityBox/WorkspaceAvailabilityBox';
import { ICalendarDayColumnProps } from './CalendarDayColumn.types';
import { resetCurrentDay } from '../../../../redux/features/scheduleSlice';
import { timeStringToDate } from '../../../../functions/functions';
import ExcludableAvailabilityBox from '../../atoms/ExcludableAvailabilityBox/ExcludableAvailabilityBox';

const CalendarDayColumn = ({
  dayOfWeek,
  dayOfWeekName,
  isForSpecialDay,
  isForExcludeTableFromAvailability,
  typeColumn,
}: ICalendarDayColumnProps) => {
  const dispatch = useAppDispatch();

  const currentColor = useAppSelector((state) => state.schedule.currentColor);

  const currentStart = useAppSelector((state) => state.schedule.currentStart);

  const currentEnd = useAppSelector((state) => state.schedule.currentEnd);

  const noAvailabilityWithinOpeningHoursError = useAppSelector(
    (state) => state.schedule.noAvailabilityWithinOpeningHoursError,
  );

  const currentAvailability = useAppSelector(
    (state) => state.schedule.currentAvailability,
  );

  const currentShift = useAppSelector((state) => state.specialDay.currentShift);

  const availabilities = useAppSelector(
    (state) => state.schedule.availabilities,
  );

  const shifts = useAppSelector((state) => state.specialDay.specialDay?.shifts);

  const openingHours = useAppSelector(
    (state) => state.schedule.openingHoursSchedule,
  );

  const specialDay = useAppSelector((state) => state.specialDay.specialDay);

  const generateScheduleRows = (): JSX.Element[] => {
    const rows = Array.from({ length: 24 }, (_, i) => (
      <div
        className={clsx(
          'z-10 flex max-h-[42px] min-h-[42px] w-full items-center justify-center border-b text-xs font-medium uppercase',
        )}
        key={`hour-${i}`}
      />
    ));

    const openingHoursForSchedule = isForSpecialDay
      ? specialDay?.openingHoursSpecialDay
      : openingHours;

    if (openingHoursForSchedule) {
      const availabilityHours = openingHoursForSchedule[dayOfWeekName] || [
        { open: '0:00', close: '0:00' },
      ];

      availabilityHours.forEach((item, index) => {
        const { open, close } = item;

        const [openHour, openMinute] = open.split(':').map(Number);
        const [closeHour, closeMinute] = close.split(':').map(Number);

        const openTime = new Date();
        openTime.setHours(openHour, openMinute, 0);

        const closeTime = new Date();
        closeTime.setHours(closeHour, closeMinute, 0);

        const timeDifference = closeTime.getTime() - openTime.getTime();
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDifference = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
        );

        const openHeight = hoursDifference * 42 + (minutesDifference / 60) * 42;
        const topHeight = openHour * 42 + (openMinute / 60) * 42;

        rows.push(
          <div
            style={{
              minHeight: `${openHeight}px`,
              maxHeight: `${openHeight}px`,
              height: `${openHeight}px`,
              top: `${topHeight}px`,
            }}
            className={clsx(
              'absolute flex w-full items-center justify-center bg-brand-300 text-xs font-medium uppercase',
            )}
            key={`${item.open}-${item.close}`}
          />,
        );
      });
    }

    return rows;
  };

  const renderAvailabilityBoxes = () => {
    return availabilities.map((item) => {
      const shouldRender = item.weekdays.includes(dayOfWeek);

      if (shouldRender) {
        if (!currentAvailability || item.id !== currentAvailability.id) {
          if (isForExcludeTableFromAvailability) {
            return (
              <ExcludableAvailabilityBox
                id={item.id}
                color={item.color}
                title={item.name}
                start={item.start}
                end={item.end}
                key={item.id}
              />
            );
          }

          return (
            <AvailabilityBox
              color={item.color}
              title={item.name}
              start={item.start}
              weekdays={item.weekdays}
              end={item.end}
              id={item.id}
              key={item.id}
            />
          );
        }
      }

      return null;
    });
  };

  const renderShiftBoxes = () => {
    if (!shifts || !specialDay || !specialDay.open || !specialDay.close) return;

    const specialDayStart = timeStringToDate(specialDay.open);
    const specialDayEnd = timeStringToDate(specialDay.close);

    const filteredShifts = shifts.filter((item) => {
      const itemStart = timeStringToDate(item.start);
      const itemEnd = timeStringToDate(item.end);

      if (typeColumn === 'right') {
        return itemEnd <= specialDayEnd && itemStart <= specialDayEnd;
      }
      return itemEnd >= specialDayStart && itemStart >= specialDayStart;
    });

    return filteredShifts.map((item) => {
      if (currentShift) {
        if (item.id !== currentShift.id) {
          return (
            <AvailabilityBox
              dayOfWeek={dayOfWeek}
              color={item.color}
              title={item.name}
              start={item.start}
              weekdays={[]}
              end={item.end}
              id={item.id}
              key={item.id}
            />
          );
        }
        return null;
      }

      return (
        <AvailabilityBox
          dayOfWeek={dayOfWeek}
          color={item.color}
          title={item.name}
          start={item.start}
          weekdays={[]}
          end={item.end}
          id={item.id}
          key={item.id}
        />
      );
    });
  };

  const renderAvailabilityWorkspaceBoxes = () => {
    let render = true;

    availabilities.forEach((item) => {
      const isDayOfWeek = item.weekdays.includes(dayOfWeek);

      if (currentStart && currentEnd) {
        if (isDayOfWeek) {
          const currentStartHourMinute = new Date(
            currentStart,
          ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

          const currentEndHourMinute = new Date(currentEnd).toLocaleTimeString(
            [],
            { hour: '2-digit', minute: '2-digit' },
          );

          const today = new Date();

          const [startHour, startMinute] = item.start.split(':').map(Number);
          const [endHour, endMinute] = item.end.split(':').map(Number);

          const itemStartDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            startHour,
            startMinute,
          );

          const itemEndDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            endHour,
            endMinute,
          );

          const itemStartHourMinute = itemStartDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });

          const itemEndHourMinute = itemEndDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });

          if (
            currentStartHourMinute < currentEndHourMinute &&
            itemStartHourMinute < itemEndHourMinute
          ) {
            if (
              currentStartHourMinute < itemEndHourMinute &&
              currentEndHourMinute > itemStartHourMinute
            ) {
              if (currentAvailability) {
                if (item.id !== currentAvailability.id) {
                  render = false;
                  dispatch(resetCurrentDay(dayOfWeek));
                }
              } else {
                render = false;
                dispatch(resetCurrentDay(dayOfWeek));
              }
            }
          }
        }
      }
    });

    if (render && currentStart && currentEnd && currentStart < currentEnd) {
      const openTime = new Date();
      const closeTime = new Date();
      const result: JSX.Element[] = [];

      if (openingHours) {
        const dayOpeningHours = openingHours[dayOfWeekName] || [
          {
            open: '0:00',
            close: '0:00',
          },
        ];

        dayOpeningHours.map((item) => {
          const { open, close } = item;

          const openTimeParts = open.split(':');
          const closeTimeParts = close.split(':');

          openTime.setHours(parseInt(openTimeParts[0], 10));
          openTime.setMinutes(parseInt(openTimeParts[1], 10));
          openTime.setSeconds(0);

          closeTime.setHours(parseInt(closeTimeParts[0], 10));
          closeTime.setMinutes(parseInt(closeTimeParts[1], 10));
          closeTime.setSeconds(0);

          const settedStart = new Date(currentStart);
          const settedEnd = new Date(currentEnd);

          const isStartAfterOpen =
            settedStart.getHours() > openTime.getHours() ||
            (settedStart.getHours() === openTime.getHours() &&
              settedStart.getMinutes() >= openTime.getMinutes());

          const isEndBeforeClose =
            settedEnd.getHours() < closeTime.getHours() ||
            (settedEnd.getHours() === closeTime.getHours() &&
              settedEnd.getMinutes() <= closeTime.getMinutes());

          if (isStartAfterOpen && isEndBeforeClose) {
            result.push(
              <WorkspaceAvailabilityBox
                color={currentColor}
                start={currentStart}
                end={currentEnd}
                dayOfWeek={dayOfWeek as number}
              />,
            );
          }
        });

        if (result.length === 0 && noAvailabilityWithinOpeningHoursError) {
          dispatch(resetCurrentDay(dayOfWeek));
        }

        return result;
      }

      dispatch(resetCurrentDay(dayOfWeek));
    }
  };

  const renderShiftsWorkspaceBoxes = () => {
    let render = true;

    if (!shifts || !specialDay || !specialDay.open || !specialDay.close) return;

    const specialDayStart = timeStringToDate(specialDay.open);
    const specialDayEnd = timeStringToDate(specialDay.close);

    const filteredShifts = shifts.filter((item) => {
      const itemStart = timeStringToDate(item.start);
      const itemEnd = timeStringToDate(item.end);

      if (typeColumn === 'right') {
        return itemEnd <= specialDayEnd && itemStart <= specialDayEnd;
      }
      return itemEnd >= specialDayStart && itemStart >= specialDayStart;
    });

    filteredShifts.forEach((item) => {
      if (currentStart && currentEnd) {
        const currentStartHourMinute = new Date(
          currentStart,
        ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        const currentEndHourMinute = new Date(currentEnd).toLocaleTimeString(
          [],
          { hour: '2-digit', minute: '2-digit' },
        );

        const today = new Date();

        const [startHour, startMinute] = item.start.split(':').map(Number);
        const [endHour, endMinute] = item.end.split(':').map(Number);

        const itemStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          startHour,
          startMinute,
        );

        const itemEndDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          endHour,
          endMinute,
        );

        const itemStartHourMinute = itemStartDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });

        const itemEndHourMinute = itemEndDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });

        if (
          currentStartHourMinute < currentEndHourMinute &&
          itemStartHourMinute < itemEndHourMinute
        ) {
          if (
            currentStartHourMinute < itemEndHourMinute &&
            currentEndHourMinute > itemStartHourMinute
          ) {
            if (currentShift) {
              if (item.id !== currentShift.id) {
                render = false;
              }
            } else {
              render = false;
            }
          }
        }
      }
    });

    if (!specialDay?.open && !specialDay?.close) return;

    if (render && currentStart && currentEnd && currentStart < currentEnd) {
      const openTime = new Date(currentStart);
      const closeTime = new Date(currentEnd);
      const result: JSX.Element[] = [];

      const specialDayOpeningHours = specialDay?.openingHoursSpecialDay;

      if (specialDayOpeningHours) {
        const dayOpeningHours = specialDayOpeningHours[dayOfWeekName] || [
          {
            open: '0:00',
            close: '0:00',
          },
        ];

        dayOpeningHours.map((item) => {
          const { open, close } = item;

          const openTimeParts = open.split(':');
          const closeTimeParts = close.split(':');

          openTime.setHours(parseInt(openTimeParts[0], 10));
          openTime.setMinutes(parseInt(openTimeParts[1], 10));
          openTime.setSeconds(0);
          openTime.setMilliseconds(0);

          closeTime.setHours(parseInt(closeTimeParts[0], 10));
          closeTime.setMinutes(parseInt(closeTimeParts[1], 10));
          closeTime.setSeconds(0);
          closeTime.setMilliseconds(0);

          const settedStart = new Date(currentStart);
          const settedEnd = new Date(currentEnd);

          if (
            (settedStart.getHours() > openTime.getHours() ||
              (settedStart.getHours() === openTime.getHours() &&
                settedStart.getMinutes() >= openTime.getMinutes())) &&
            (settedEnd.getHours() < closeTime.getHours() ||
              (settedEnd.getHours() === closeTime.getHours() &&
                settedEnd.getMinutes() <= closeTime.getMinutes()))
          ) {
            result.push(
              <WorkspaceAvailabilityBox
                color={currentColor}
                start={currentStart}
                end={currentEnd}
                dayOfWeek={dayOfWeek as number}
              />,
            );
          }
        });

        return result;
      }
    }
  };

  return (
    <div className="relative flex flex-1 flex-col items-center border-r bg-brand-100">
      {generateScheduleRows()}
      {isForSpecialDay ? renderShiftBoxes() : renderAvailabilityBoxes()}
      {!isForExcludeTableFromAvailability &&
        (isForSpecialDay
          ? renderShiftsWorkspaceBoxes()
          : renderAvailabilityWorkspaceBoxes())}
    </div>
  );
};

export default CalendarDayColumn;
