import { createSlice } from '@reduxjs/toolkit';

import {
  getCategoriesThunk,
  getPlaceFirstStepsFormValuesThunk,
  updateVisibilityThunk,
} from '../thunks/firstStepsThunk';
import { IFirstStepsSliceState } from '../../types/FirstSteps';
import { setPaymentMethodThunk } from '../thunks/settingsThunk';

const initialState: IFirstStepsSliceState = {
  categories: null,
  place: null,
  getPlaceStatus: null,
  updatePlaceStatus: null,
  getCategoriesStatus: null,
  updateOpeningHoursStatus: null,
  currentStep: 1,
  setPaymentMethodStatus: null,
};

export const firstStepsSlice = createSlice({
  name: 'firstSteps',
  initialState,
  reducers: {
    incrementCurrentStep: (state) => {
      state.currentStep += 1;
    },
    decrementCurrentStep: (state) => {
      state.currentStep -= 1;
    },
    clearFirstSteps: () => initialState,
  },
  extraReducers: (builder) => {
    // getCategories
    builder
      .addCase(getCategoriesThunk.pending, (state) => {
        state.getCategoriesStatus = 'loading';
      })
      .addCase(getCategoriesThunk.fulfilled, (state, { payload }) => {
        state.categories = payload;
        state.getCategoriesStatus = 'success';
      })
      .addCase(getCategoriesThunk.rejected, (state) => {
        state.getCategoriesStatus = 'failed';
      });
    builder
      .addCase(getPlaceFirstStepsFormValuesThunk.pending, (state) => {
        state.getPlaceStatus = 'loading';
      })
      .addCase(
        getPlaceFirstStepsFormValuesThunk.fulfilled,
        (state, { payload }) => {
          state.place = payload;
          state.getPlaceStatus = 'success';
        },
      )
      .addCase(getPlaceFirstStepsFormValuesThunk.rejected, (state) => {
        state.getPlaceStatus = 'failed';
      });
    builder
      .addCase(updateVisibilityThunk.pending, (state) => {
        state.updatePlaceStatus = 'loading';
      })
      .addCase(updateVisibilityThunk.fulfilled, (state, { payload }) => {
        if (!payload) return;
        if (!state.place) return;
        state.place.logo = payload.logo;
        state.place.description = payload.description;
        state.place.priceRange = payload.priceRange;
        state.place.slogan = payload.slogan;
        state.place.subcategoriesIds = payload.subcategoriesIds;

        state.updatePlaceStatus = 'success';
      })
      .addCase(updateVisibilityThunk.rejected, (state) => {
        state.updatePlaceStatus = 'failed';
      });
    builder
      .addCase(setPaymentMethodThunk.pending, (state) => {
        state.setPaymentMethodStatus = 'loading';
      })
      .addCase(setPaymentMethodThunk.fulfilled, (state, { payload }) => {
        state.setPaymentMethodStatus = 'success';
        if (!state.place) return;
        state.place.paymentMethods = payload;
      })
      .addCase(setPaymentMethodThunk.rejected, (state) => {
        state.setPaymentMethodStatus = 'failed';
      });
  },
});

export const { incrementCurrentStep, decrementCurrentStep, clearFirstSteps } =
  firstStepsSlice.actions;

export default firstStepsSlice.reducer;
