import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Flowbite, Datepicker } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format, isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';

import { IInputDateProps } from './InputDate.types';
import InputLabel from '../../atoms/InputLabel/InputLabel';
import InputErrorMessage from '../../atoms/InputErrorMessage/InputErrorMessage';
import { customFlowBiteTheme } from '../../../../constants/flowbiteCustomTheme';
import { useDetectOutsideClick } from '../../../../hooks/useDetectOutsideClick';
import InputArea from '../../atoms/InputArea/InputArea';
import CollapseDateButton from '../../atoms/CollapseDateButton/CollapseDateButton';
import Button from '../Button/Button';
import useIsBreakpoint from '../../../../hooks/useIsBreakpoint';
import { BREAKPOINTS } from '../../../../constants/constants';
import i18n from '../../../../configuration/i18n';

const InputDate = ({
  name,
  labelText,
  placeholder,
  value,
  onChange,
  error,
  isDisabled,
  isCollapse = false,
  inputSubText = '',
  shortFormatYear = false,
  isPositionOnTop = false,
  onClear,
  minDate,
  maxDate,
  disableYears,
}: IInputDateProps) => {
  const datePickerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const isGalaxyFold = useIsBreakpoint(BREAKPOINTS.xsMobile);

  const [showPicker, setShowPicker] = useState<boolean>(false);

  const [monthChange, setMonthChange] = useState<boolean>(false);

  const today = new Date();

  const [isPickerOutOfBounds, setPickerOutOfBounds] = useState<boolean>(false);
  const [isBottomEdgeCollision, setIsBottomEdgeCollision] =
    useState<boolean>(false);

  const handleDateFormat = () => {
    if (!value) return '';
    if (disableYears) return format(Number(value), 'dd.MM');
    if (shortFormatYear) return format(Number(value), 'dd.MM.yy');
    return format(Number(value), 'dd.MM.yyyy');
  };

  const formatedDate = handleDateFormat();

  const checkPickerPosition = () => {
    if (datePickerRef.current) {
      const rect = datePickerRef.current.getBoundingClientRect();
      if (rect.left < 0) {
        setPickerOutOfBounds(true);
      } else {
        setPickerOutOfBounds(false);
      }
      if (rect.bottom > window.innerHeight) {
        setIsBottomEdgeCollision(true);
      } else {
        setIsBottomEdgeCollision(false);
      }
    }
  };

  const handleInputClick = () => {
    setShowPicker(!showPicker);
  };

  const handleInputClose = () => {
    setShowPicker(false);
  };

  const handleDateChange = (selectedDate: Date) => {
    onChange(selectedDate);
    handleInputClose();
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    handleInputClose();
  };

  const handleSetTodaysDate = () => {
    if (minDate && isBefore(today, minDate)) {
      toast.error(t('errorMessages.cantPickThisDate'));
    } else if (maxDate && isAfter(today, maxDate)) {
      toast.error(t('errorMessages.cantPickThisDate'));
    } else {
      handleDateChange(today);
    }
  };

  useDetectOutsideClick(datePickerRef, handleInputClose);

  useLayoutEffect(() => {
    if (showPicker) {
      checkPickerPosition();
      window.addEventListener('resize', checkPickerPosition);

      return () => {
        window.removeEventListener('resize', checkPickerPosition);
      };
    }
    setPickerOutOfBounds(false);
    setIsBottomEdgeCollision(false);
  }, [showPicker]);

  const handleModifyMonthHeader = () => {
    const currentMonthButton = document.querySelector('.flowbite-month-button');
    if (currentMonthButton?.textContent) {
      const buttonTextWithoutYear =
        currentMonthButton.textContent.split(' ')[0];
      currentMonthButton.textContent = buttonTextWithoutYear;
      currentMonthButton.classList.add('pointer-events-none');
    }
  };

  useEffect(() => {
    if (disableYears) {
      handleModifyMonthHeader();
    }
  }, [showPicker, monthChange, disableYears]);

  return (
    <div className="flex w-full flex-1 flex-col">
      {labelText && (
        <InputLabel
          text={labelText}
          htmlFor={name}
          variant="secondary"
          isDisabled={isDisabled}
        />
      )}
      <div className={clsx('relative', isDisabled && 'pointer-events-none')}>
        {isCollapse ? (
          <CollapseDateButton
            handleClick={handleInputClick}
            subText={inputSubText}
            text={formatedDate}
            showPicker={showPicker}
          />
        ) : (
          <InputArea
            isReadOnly
            isDisabled={isDisabled}
            isDateInput
            name={name}
            type="text"
            variant="secondary"
            placeholder={placeholder || t('messages.pickStartDate')}
            value={formatedDate}
            isError={!!error}
            onChange={(e) => onChange(new Date(e.target.value))}
            onClick={handleInputClick}
          />
        )}
        {showPicker && (
          <div
            ref={datePickerRef}
            onClick={() => setMonthChange(!monthChange)}
            className={clsx(
              'z-50 rounded-xs border-[1px] border-solid border-brand-700 bg-brand-50 p-3',
              isPositionOnTop || isGalaxyFold ? 'fixed' : 'absolute',
              (isPickerOutOfBounds || isGalaxyFold) && 'left-0',
              !isPickerOutOfBounds && !isPositionOnTop && 'right-0',
              isBottomEdgeCollision && 'bottom-12',
            )}
          >
            <Flowbite
              theme={{
                dark: true,
                theme: customFlowBiteTheme,
              }}
            >
              <Datepicker
                inline
                language={i18n.language}
                weekStart={2}
                defaultDate={value}
                onSelectedDateChanged={(e) => handleDateChange(e)}
                minDate={minDate ?? undefined}
                maxDate={maxDate ?? undefined}
              />
            </Flowbite>
            <div className="flex gap-4">
              {onClear && (
                <Button
                  type="button"
                  variant="octonary"
                  text={t('messages.clear')}
                  onClick={handleClear}
                />
              )}
              <Button
                type="button"
                variant="septenary"
                text={t('messages.today')}
                onClick={handleSetTodaysDate}
              />
            </div>
          </div>
        )}
      </div>
      {!!error && <InputErrorMessage error={error} />}
    </div>
  );
};

export default InputDate;
