import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AxiosError } from 'axios';

import { IUpdateVisibilityThunk } from '../../types/FirstSteps';
import { REST_API_URLS } from '../../constants/constants';
import { GetPlaceFirstStepsFormValues } from '../../schema/FirstStepsSchemas';
import categoriesData from '../../constants/categories.json';
import { axiosMiddleware } from '../../configuration/axiosMiddleware';
import { errorSchema } from '../../schema/ErrorSchemas';
import {
  getBunnyImage,
  removeImageFromStorage,
  uploadPhoto,
} from '../../functions/storage';

export const getCategoriesThunk = createAsyncThunk(
  'firstSteps/getCategories',
  async (_, { rejectWithValue }) => {
    try {
      const { categories } = categoriesData;

      return categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPlaceFirstStepsFormValuesThunk = createAsyncThunk(
  'firstSteps/getPlaceFirstStepsFormValues',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosMiddleware({
        url: REST_API_URLS.getPlaceFirstStepsFormValues,
        method: 'GET',
      });

      const validatedData = GetPlaceFirstStepsFormValues.parse(response);
      const data = {
        ...validatedData.data,
        logo: validatedData.data.logo
          ? getBunnyImage(validatedData.data.logo, '300', '300')
          : null,
      };

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateVisibilityThunk = createAsyncThunk(
  'firstSteps/updateVisibility',
  async (
    {
      logo,
      oldImageUrl,
      placeId,
      slogan,
      description,
      subcategoriesIds,
      priceRange,
      minReservationTime,
      maxReservationTime,
      avgReservationTime,
      slotInterval,
      timezone,
      encodedName,
      callback,
    }: IUpdateVisibilityThunk,
    { rejectWithValue },
  ) => {
    try {
      let photoUrl: string | null = oldImageUrl;

      if (logo instanceof File) {
        if (oldImageUrl) {
          removeImageFromStorage(oldImageUrl, 'settings');
        }
        const uploadedImage = await uploadPhoto(logo, 'settings');
        photoUrl = uploadedImage.photoUrl;
      }
      if (logo === null) photoUrl = null;

      await axiosMiddleware({
        url: REST_API_URLS.updatePlace,
        method: 'PATCH',
        data: {
          logo: photoUrl,
          placeId,
          slogan,
          description,
          subcategoriesIds,
          priceRange,
          minReservationTime,
          maxReservationTime,
          avgReservationTime,
          slotInterval,
          timezone,
          encodedName,
        },
      });

      callback();

      return {
        logo: photoUrl ? getBunnyImage(photoUrl, '300', '300') : null,
        slogan,
        description,
        subcategoriesIds,
        priceRange,
        minReservationTime,
        maxReservationTime,
        avgReservationTime,
        slotInterval,
        timezone,
        encodedName,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const parsedError = errorSchema.safeParse(error.response?.data);

        if (!parsedError.success) {
          toast.error(t('errorMessages.somethingWentWrongDuringUpdatingPlace'));
          return rejectWithValue(error);
        }

        const { errorCode } = parsedError.data;
        if (errorCode === 'ENCODED_NAME_TAKEN') {
          toast(t('errorMessages.encodedNameTaken'));
        }
      } else {
        toast(t('errorMessages.somethingWentWrongDuringUpdatingPlace'));
      }
      return rejectWithValue(error);
    }
  },
);
