import { getDay } from 'date-fns';

import CalendarHeader from '../../molecules/CalendarHeader/CalendarHeader';
import CalendarContainer from '../../molecules/CalendarContainer/CalendarContainer';
import { STANDARD_SCHEDULE_NUMBER_OF_DAY } from '../../../../constants/constants';
import { useAppSelector } from '../../../../hooks/useRedux';
import { ISpecialDay } from '../../../../types/SpecialDay';

export const getDaysToDisplayInSchedule = (
  specialDay: ISpecialDay,
): number[] => {
  const parsedDate = new Date(specialDay.date);
  const dayOfWeek = getDay(parsedDate);
  const nextDayOfWeek = (dayOfWeek + 1) % 7;

  if (!specialDay.open || !specialDay.close) {
    return [dayOfWeek];
  }

  const [openHour, openMinutes] = specialDay.open.split(':').map(Number);
  const [closeHour, closeMinutes] = specialDay.close.split(':').map(Number);

  const isOvernight =
    closeHour < openHour ||
    (closeHour === openHour && closeMinutes < openMinutes) ||
    specialDay.close === '00:00';

  return isOvernight ? [dayOfWeek, nextDayOfWeek] : [dayOfWeek];
};

const Calendar = ({ isForSpecialDay }: { isForSpecialDay?: boolean }) => {
  const specialDay = useAppSelector((state) => state.specialDay.specialDay);

  let daysToShow: number | number[] = STANDARD_SCHEDULE_NUMBER_OF_DAY;

  if (isForSpecialDay) {
    if (specialDay) {
      daysToShow = getDaysToDisplayInSchedule(specialDay);
    } else {
      daysToShow = [];
    }
  }

  return (
    <div className="h-full w-full flex-col overflow-auto border xl:border-l-0">
      <CalendarHeader daysToShow={daysToShow} />
      <CalendarContainer
        daysToShow={daysToShow}
        isForSpecialDay={isForSpecialDay}
      />
    </div>
  );
};

export default Calendar;
