import { createSlice } from '@reduxjs/toolkit';

import {
  createSpecialDayThunk,
  listSpecialDaysThunk,
  deleteSpecialDayThunk,
  updateSpecialDayThunk,
  listSpecialDaysCountThunk,
  listSpecialDaysAvailabilitiesThunk,
  createSpecialDayAvailabilityThunk,
  deleteSpecialDayAvailabilityThunk,
  updateSpecialDayAvailabilityThunk,
  importSpecialDayAvailabilitiesThunk,
} from '../thunks/specialDayThunk';
import { ISpecialDay, ISpecialDaySlice } from '../../types/SpecialDay';
import { convertOpeningHoursForSpecialDaySchedule } from '../../functions/functions';

const initialState: ISpecialDaySlice = {
  specialDays: [],
  total: 0,
  specialDay: null,
  specialDaysCount: 0,
  currentShift: null,
  getSpecialDaysStatus: null,
  getSpecialDaysCountStatus: null,
  createSpecialDayStatus: null,
  updateSpecialDayStatus: null,
  deleteSpecialDayStatus: null,
  importSpecialDayAvailabilitiesStatus: null,
  isEdit: false,
  specialDayModalVisible: false,
  specialDayShiftVisible: false,
  listSpecialDaysCountThunkStatus: null,
  createSpecialDayAvailabilityStatus: null,
  deleteSpecialDayAvailabilityStatus: null,
  updateSpecialDayAvailabilityStatus: null,
};

export const specialDaySlice = createSlice({
  name: 'specialDay',
  initialState,
  reducers: {
    clearSpecialDays: () => initialState,
    showAddSpecialDayModal: (state) => {
      state.specialDayModalVisible = true;
    },
    showEditSpecialDayModal: (state, { payload }) => {
      state.specialDay = payload;
      state.deleteSpecialDayStatus = initialState.deleteSpecialDayStatus;
      state.isEdit = true;
      state.specialDayModalVisible = true;
    },
    setSpecialDay: (state, { payload }) => {
      state.deleteSpecialDayStatus = initialState.deleteSpecialDayStatus;
      state.specialDay = payload;
    },
    closeSpecialDayModal: (state) => {
      state.isEdit = initialState.isEdit;
      state.specialDayModalVisible = initialState.specialDayModalVisible;
    },
    showSpecialDayShiftModal: (state) => {
      state.specialDayShiftVisible = true;
    },
    closeSpecialDayShiftModal: (state) => {
      state.specialDayShiftVisible = initialState.specialDayShiftVisible;
    },
    setEditShift: (state, { payload }) => {
      state.currentShift = payload;
      state.specialDayShiftVisible = true;
    },
    resetEditShift: (state) => {
      state.currentShift = initialState.currentShift;
      state.specialDayShiftVisible = initialState.specialDayShiftVisible;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listSpecialDaysAvailabilitiesThunk.pending, (state) => {
      state.getSpecialDaysStatus = 'loading';
    });
    builder.addCase(
      listSpecialDaysAvailabilitiesThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;

        state.specialDay = {
          ...payload,
          openingHoursSpecialDay:
            payload.open && payload.close
              ? convertOpeningHoursForSpecialDaySchedule(
                  payload.open,
                  payload.close,
                  payload.date,
                )
              : {},
        };

        state.getSpecialDaysStatus = 'success';
      },
    );
    builder.addCase(listSpecialDaysAvailabilitiesThunk.rejected, (state) => {
      state.getSpecialDaysStatus = 'failed';
    });

    builder.addCase(listSpecialDaysThunk.pending, (state) => {
      state.getSpecialDaysStatus = 'loading';
    });
    builder.addCase(listSpecialDaysThunk.fulfilled, (state, { payload }) => {
      state.getSpecialDaysStatus = 'success';
      if (!payload) return;

      state.specialDays = payload.specialDays.map((specialDay: any) => ({
        ...specialDay,
        date: specialDay.date,
        shifts: specialDay.Availability,
        openingHoursSpecialDay:
          specialDay.open && specialDay.close
            ? convertOpeningHoursForSpecialDaySchedule(
                specialDay.open,
                specialDay.close,
                specialDay.date,
              )
            : {},
      }));
    });
    builder.addCase(listSpecialDaysThunk.rejected, (state) => {
      state.getSpecialDaysStatus = 'failed';
    });

    builder.addCase(createSpecialDayThunk.pending, (state) => {
      state.createSpecialDayStatus = 'loading';
    });
    builder.addCase(createSpecialDayThunk.fulfilled, (state, { payload }) => {
      state.createSpecialDayStatus = 'success';
      if (!payload) return;

      const specialDayWithOpeningHoursSpecialDay = {
        ...payload,
        openingHoursSpecialDay:
          payload.open && payload.close
            ? convertOpeningHoursForSpecialDaySchedule(
                payload.open,
                payload.close,
                payload.date,
              )
            : {},
      };

      state.specialDays.push(specialDayWithOpeningHoursSpecialDay);
      state.total += 1;
    });
    builder.addCase(createSpecialDayThunk.rejected, (state) => {
      state.createSpecialDayStatus = 'failed';
    });

    builder.addCase(updateSpecialDayThunk.pending, (state) => {
      state.updateSpecialDayStatus = 'loading';
    });
    builder.addCase(updateSpecialDayThunk.fulfilled, (state, { payload }) => {
      state.updateSpecialDayStatus = 'success';
      if (!payload) return;

      const updatedSpecialDays = state.specialDays.map((specialDay) => {
        if (specialDay.id === payload.id) {
          const updatedSpecialDay = {
            ...specialDay,
            name: payload.name ?? specialDay.name,
            isOpen: payload.isOpen ?? specialDay.isOpen,
            date: payload.date ? payload.date : specialDay.date,
            open: payload.open,
            close: payload.close,
            openingHoursSpecialDay:
              payload.open && payload.close
                ? convertOpeningHoursForSpecialDaySchedule(
                    payload.open,
                    payload.close,
                    payload.date ? payload.date : specialDay.date,
                  )
                : {},
          };

          state.specialDay = updatedSpecialDay;
          return updatedSpecialDay;
        }
        return specialDay;
      });

      state.specialDays = updatedSpecialDays;
      state.specialDayModalVisible = initialState.specialDayModalVisible;
      state.isEdit = initialState.isEdit;
    });
    builder.addCase(updateSpecialDayThunk.rejected, (state) => {
      state.updateSpecialDayStatus = 'failed';
    });

    builder.addCase(deleteSpecialDayThunk.pending, (state) => {
      state.deleteSpecialDayStatus = 'loading';
    });
    builder.addCase(deleteSpecialDayThunk.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.specialDays = state.specialDays.filter(
        (specialDay: ISpecialDay) => specialDay.id !== payload,
      );

      state.total -= 1;

      state.deleteSpecialDayStatus = 'success';
    });

    builder.addCase(deleteSpecialDayThunk.rejected, (state) => {
      state.deleteSpecialDayStatus = 'failed';
    });

    builder.addCase(importSpecialDayAvailabilitiesThunk.pending, (state) => {
      state.importSpecialDayAvailabilitiesStatus = 'loading';
    });
    builder.addCase(
      importSpecialDayAvailabilitiesThunk.fulfilled,
      (state, { payload }) => {
        if (!payload || !state.specialDay) return;

        const { specialDayId, importedSpecialDayAvailabilities } = payload;

        if (state.specialDay.id === specialDayId) {
          state.specialDay.shifts = importedSpecialDayAvailabilities;
        }

        state.importSpecialDayAvailabilitiesStatus = 'success';
      },
    );
    builder.addCase(importSpecialDayAvailabilitiesThunk.rejected, (state) => {
      state.importSpecialDayAvailabilitiesStatus = 'failed';
    });

    // List special days count
    builder.addCase(listSpecialDaysCountThunk.pending, (state) => {
      state.listSpecialDaysCountThunkStatus = 'loading';
    });
    builder.addCase(
      listSpecialDaysCountThunk.fulfilled,
      (state, { payload }) => {
        state.listSpecialDaysCountThunkStatus = 'success';
        state.total = payload;
      },
    );
    builder.addCase(listSpecialDaysCountThunk.rejected, (state) => {
      state.listSpecialDaysCountThunkStatus = 'failed';
    });
    builder.addCase(createSpecialDayAvailabilityThunk.pending, (state) => {
      state.createSpecialDayAvailabilityStatus = 'loading';
    });
    builder.addCase(
      createSpecialDayAvailabilityThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;

        if (state.specialDay && state.specialDay.id === payload.specialDayId) {
          const newSpecialDayAvailability = {
            id: payload.createdSpecialDayAvailability.id,
            name: payload.createdSpecialDayAvailability.name,
            start: payload.createdSpecialDayAvailability.start,
            end: payload.createdSpecialDayAvailability.end,
            color: payload.createdSpecialDayAvailability.color,
          };

          const updatedSpecialDay: ISpecialDay = {
            ...state.specialDay,
            shifts: [...state.specialDay.shifts, newSpecialDayAvailability],
          };

          state.specialDay = updatedSpecialDay;
        }

        state.createSpecialDayAvailabilityStatus = 'success';
      },
    );
    builder.addCase(createSpecialDayAvailabilityThunk.rejected, (state) => {
      state.createSpecialDayAvailabilityStatus = 'failed';
    });
    builder.addCase(deleteSpecialDayAvailabilityThunk.pending, (state) => {
      state.deleteSpecialDayAvailabilityStatus = 'loading';
    });
    builder.addCase(
      deleteSpecialDayAvailabilityThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;

        if (state.specialDay && state.specialDay.id === payload.specialDayId) {
          const updatedSpecialDay: ISpecialDay = {
            ...state.specialDay,
            shifts: state.specialDay.shifts.filter(
              (shift) => shift.id !== payload.availabilityId,
            ),
          };

          state.specialDay = updatedSpecialDay;
        }

        state.deleteSpecialDayAvailabilityStatus = 'success';
      },
    );

    builder.addCase(deleteSpecialDayAvailabilityThunk.rejected, (state) => {
      state.deleteSpecialDayAvailabilityStatus = 'failed';
    });
    builder.addCase(updateSpecialDayAvailabilityThunk.pending, (state) => {
      state.updateSpecialDayAvailabilityStatus = 'loading';
    });
    builder.addCase(
      updateSpecialDayAvailabilityThunk.fulfilled,
      (state, { payload }) => {
        if (!payload) return;

        if (state.specialDay && state.specialDay.id === payload.specialDayId) {
          const updatedShifts = state.specialDay.shifts.map((shift) => {
            if (shift.id === payload.availabilityId) {
              const { id, ...rest } = payload.updatedSpecialDayAvailability;

              return { ...shift, ...rest, id };
            }
            return shift;
          });

          const updatedSpecialDay = {
            ...state.specialDay,
            shifts: updatedShifts,
          };

          state.specialDay = updatedSpecialDay;
        }

        state.updateSpecialDayAvailabilityStatus = 'success';
      },
    );

    builder.addCase(updateSpecialDayAvailabilityThunk.rejected, (state) => {
      state.updateSpecialDayAvailabilityStatus = 'failed';
    });
  },
});

export const {
  clearSpecialDays,
  showAddSpecialDayModal,
  closeSpecialDayModal,
  showEditSpecialDayModal,
  setSpecialDay,
  showSpecialDayShiftModal,
  closeSpecialDayShiftModal,
  setEditShift,
  resetEditShift,
} = specialDaySlice.actions;

export default specialDaySlice.reducer;
