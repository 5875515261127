import { useTranslation } from 'react-i18next';

import { IPaginationSelectorCountProps } from './PaginationSelectorCount.types';
import InputDropdown from '../../atoms/InputDropdown/InputDropdown';

const PaginationSelectorCount = ({
  total,
  pageLimit,
  currentPage,
  setPageLimit,
}: IPaginationSelectorCountProps) => {
  const { t } = useTranslation();
  const from = currentPage * pageLimit + 1;
  const to =
    total > currentPage * pageLimit + pageLimit
      ? currentPage * pageLimit + pageLimit
      : total;

  const options = [
    { label: '10', onClick: () => setPageLimit(10) },
    { label: '20', onClick: () => setPageLimit(20) },
    { label: '30', onClick: () => setPageLimit(30) },
    { label: '40', onClick: () => setPageLimit(40) },
    { label: '50', onClick: () => setPageLimit(50) },
  ];

  return (
    <div className="flex items-center gap-2">
      <div className="text-sm text-brand-1700">
        {t('messages.positionsPerPage')}
      </div>
      <InputDropdown options={options} isPositionedOnTop variant="pagination" />
      <div className="flex gap-1 text-sm font-medium">
        <span>
          {from}-{to}
        </span>
        <span className="text-brand-1700">z</span>
        <span>{total}</span>
      </div>
    </div>
  );
};

export default PaginationSelectorCount;
