import React, { Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { routes } from '../constants/constants';
import {
  FirstStepsRoute,
  GuestRoute,
  PermissionRoute,
  PrivateRoute,
  EmployeeRoute,
} from '../auth';
import PrivateTemplate from '../components/templates/PrivateTemplate/PrivateTemplate';
import PublicTemplate from '../components/templates/PublicTemplate/PublicTemplate';
import AvailabilityTemplate from '../components/templates/AvailabilityTemplate/AvailabilityTemplate';
import SpecialDaySchedulePage from '../components/pages/SpecialDaySchedulePage/SpecialDaySchedulePage';
import FirstStepsTemplate from '../components/templates/FirstStepsTemplate/FirstStepsTemplate';
import SectionLoader from '../components/UI/atoms/SectionLoader/SectionLoader';
import EventsPage from '../components/pages/EventsPage/EventsPage';
import ReservationsTemplate from '../components/templates/ReservationsTemplate/ReservationsTemplate';
import NewPasswordRoute from '../auth/NewPasswordRoute';
import DisabledChatRoute from '../auth/DisabledChatRoute';
import RootBoundaryPage from '../components/pages/RootBoundaryPage/RootBoundaryPage';
import SupportPage from '../components/pages/SupportPage/SupportPage';
import OfferPage from '../components/pages/OfferPage/OfferPage';

// Lazy loaded components
const SummaryPage = React.lazy(
  () => import('../components/pages/SummaryPage/SummaryPage'),
);
const ReservationsPage = React.lazy(
  () => import('../components/pages/ReservationsPage/ReservationsPage'),
);
const UnseenReservationsPage = React.lazy(
  () => import('../components/pages/UnseenMessagesPage/UnseenMessagesPage'),
);
const CouponsPage = React.lazy(
  () => import('../components/pages/CouponsPage/CouponsPage'),
);
const MenuPage = React.lazy(
  () => import('../components/pages/MenuPage/MenuPage'),
);
const StatisticsPage = React.lazy(
  () => import('../components/pages/StatisticsPage/StatisticsPage'),
);
const SettingsPage = React.lazy(
  () => import('../components/pages/SettingsPage/SettingsPage'),
);
const LoginPage = React.lazy(
  () => import('../components/pages/LoginPage/LoginPage'),
);
const SpacePlanPage = React.lazy(
  () => import('../components/pages/SpacePlanPage/SpacePlanPage'),
);
const SchedulePage = React.lazy(
  () => import('../components/pages/SchedulePage/SchedulePage'),
);
const SpecialDaysPage = React.lazy(
  () => import('../components/pages/SpecialDaysPage/SpecialDaysPage'),
);
const PasswordRecoveryPage = React.lazy(
  () => import('../components/pages/PasswordRecoveryPage/PasswordRecoveryPage'),
);
const NewPasswordPage = React.lazy(
  () => import('../components/pages/NewPasswordPage/NewPasswordPage'),
);
const FirstStepsPage = React.lazy(
  () => import('../components/pages/FirstStepsPage/FirstStepsPage'),
);

// const isAdmin = useAppSelector((state) => state.user.user?.role === 'ADMIN');

const router = createBrowserRouter([
  {
    path: '/',
    element: <PermissionRoute />,
    errorElement: <RootBoundaryPage />,
    children: [
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <PrivateTemplate />,
            children: [
              {
                path: routes.empty,
                element: <Navigate to={routes.summary} replace />,
              },
              {
                path: routes.home,
                element: <Navigate to={routes.summary} replace />,
              },
              {
                path: routes.summary,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <SummaryPage />
                  </Suspense>
                ),
              },
              {
                path: routes.reservations,
                element: <ReservationsTemplate />,
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<SectionLoader />}>
                        <ReservationsPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: routes.reservationsUnseen,
                    element: (
                      <Suspense fallback={<SectionLoader />}>
                        <DisabledChatRoute>
                          <UnseenReservationsPage />
                        </DisabledChatRoute>
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: '',
                element: <AvailabilityTemplate />,
                children: [
                  {
                    path: routes.availabilitySpacePlan,
                    element: <EmployeeRoute />,
                    children: [
                      {
                        path: '',
                        element: (
                          <Suspense fallback={<SectionLoader />}>
                            <SpacePlanPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: routes.availabilitySchedule,
                    element: (
                      <Suspense fallback={<SectionLoader />}>
                        <SchedulePage />
                      </Suspense>
                    ),
                  },
                  {
                    path: routes.availabilitySpecialDays,
                    element: (
                      <Suspense fallback={<SectionLoader />}>
                        <SpecialDaysPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: `${routes.availabilitySpecialDaysSchedule}/:specialDayId`,
                    element: (
                      <Suspense fallback={<SectionLoader />}>
                        <SpecialDaySchedulePage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: routes.coupons,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <CouponsPage />
                  </Suspense>
                ),
              },
              {
                path: routes.events,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <EventsPage />
                  </Suspense>
                ),
              },
              {
                path: routes.menu,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <MenuPage />
                  </Suspense>
                ),
              },
              {
                path: routes.offer,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <OfferPage />
                  </Suspense>
                ),
              },
              {
                path: `${routes.offer}/:offerId`,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <MenuPage />
                  </Suspense>
                ),
              },

              {
                path: routes.statistics,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <StatisticsPage />
                  </Suspense>
                ),
              },
              {
                path: routes.settings,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <SettingsPage />
                  </Suspense>
                ),
              },
              {
                path: routes.support,
                element: (
                  <Suspense fallback={<SectionLoader />}>
                    <SupportPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <NewPasswordRoute />,
    children: [
      {
        path: routes.newPassword,
        element: (
          <Suspense fallback={<SectionLoader />}>
            <NewPasswordPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    element: <FirstStepsRoute />,
    children: [
      {
        path: routes.firstSteps,
        element: <FirstStepsTemplate />,
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<SectionLoader />}>
                <FirstStepsPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    element: <GuestRoute />,
    children: [
      {
        path: '/',
        element: <PublicTemplate />,
        errorElement: <RootBoundaryPage />,
        children: [
          {
            path: routes.login,
            element: (
              <Suspense fallback={<SectionLoader />}>
                <LoginPage />
              </Suspense>
            ),
          },
          {
            path: routes.passwordRecovery,
            element: (
              <Suspense fallback={<SectionLoader />}>
                <PasswordRecoveryPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
