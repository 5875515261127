import { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import ArrowIcon from '../../../../assets/icons/ArrowIcon';

interface Option {
  value: string;
  label: string;
  onClick?: () => void;
}

interface SimpleDropdownProps {
  options: Option[];
  selectedValue: string;
  onChange: (value: string) => void;
  formatLabel?: (value: string) => string;
  placeholder?: string;
  isDisabled?: boolean;
  error?: string;
}

const SimpleDropdown: FC<SimpleDropdownProps> = ({
  options,
  selectedValue,
  onChange,
  formatLabel,
  placeholder,
  isDisabled = false,
  error,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const shownValue = formatLabel
    ? formatLabel(selectedValue)
    : options.find((option) => option.value === selectedValue)?.label || '';

  const handleToggleOpen = () => {
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (value: string, onClick?: () => void) => {
    if (onClick) onClick();
    onChange(value);
    setIsOpen(false);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        handleCloseDropdown();
      }
    };

    if (isOpen) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'md:min-w-[340px relative flex h-[42px] items-center justify-center rounded-xs border-[1px] border-solid',
        error && 'border-red-600',
        isDisabled && 'pointer-events: none',
      )}
    >
      <button
        type="button"
        className="flex w-full justify-between gap-2.5 px-4 py-3"
        onClick={handleToggleOpen}
        disabled={isDisabled}
      >
        <span
          className={clsx(
            'truncate',
            shownValue ? 'text-white' : 'text-brand-1700',
          )}
        >
          {shownValue || placeholder}
        </span>
        <span
          className={clsx(
            ' duration-100 ease-in-out',
            isOpen ? 'rotate-0' : 'rotate-180',
          )}
        >
          <ArrowIcon isDisabled={isDisabled} />
        </span>
      </button>

      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute top-[-268px] z-10 flex max-h-[234px] w-full flex-col gap-4 rounded-xs border bg-brand-50 p-5"
        >
          <div
            className={clsx(
              'custom-scrollbar scrolling flex flex-col gap-4 overflow-auto',
            )}
          >
            {options.map((option) => (
              <button
                key={option.value}
                type="button"
                className="flex items-center justify-between gap-2"
                onClick={() => handleOptionClick(option.value, option.onClick)}
              >
                <span className="flex min-w-[90px] gap-1">
                  <span className="truncate">{option.label}</span>
                </span>
              </button>
            ))}
          </div>
        </div>
      )}

      {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
    </div>
  );
};

export default SimpleDropdown;
