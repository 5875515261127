import { t } from 'i18next';
import z from 'zod';

export const CreateTablesGroupFormSchema = z.object({
  name: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  spacePlanId: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
});

export const AddTableToGroupFormSchema = z.object({
  tableId: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
});

export const DropdownCheckboxesSchema = z.object({
  id: z.string(),
  name: z.string(),
  option: z.string(),
});

export const CouponFormSchema = z
  .object({
    image: z.union([z.instanceof(File), z.string(), z.null()]).refine(
      (data) => {
        if (!data || data === null) {
          return false;
        }
        return true;
      },
      {
        message: t('errorMessages.thisFieldIsRequired'),
      },
    ),
    name: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    discount: z.string().min(1, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
    description: z.string(),
    category: z.array(DropdownCheckboxesSchema).optional(),
    startDate: z.optional(z.date()),
    endDate: z.optional(z.date()),
    isOnetime: z.enum(['single', 'multiple']),
    maxNumberOfCoupons: z
      .string()
      .nullable()
      .refine((val) => val !== null && parseInt(val, 10) > 0, {
        message: t('errorMessages.mustBePositiveNumber'),
      })
      .refine((val) => val !== null && Number.isInteger(parseInt(val, 10)), {
        message: t('errorMessages.mustBeAnInteger'),
      }),
    isActive: z.enum(['active', 'inactive']),
    couponCode: z.string(),
    isForAdults: z.boolean(),
  })
  .refine(
    (data) => {
      if (data.isOnetime === 'multiple') {
        if (!data.couponCode || data.couponCode.trim() === '') {
          return false;
        }
      }
      return true;
    },
    {
      message: t('errorMessages.thisFieldIsRequired'),
      path: ['couponCode'],
    },
  );

export const OfferFormSchema = z.object({
  name: z.string().min(1, {
    message: t('errorMessages.thisFieldIsRequired'),
  }),
  offerCategory: z
    .preprocess(
      (value) => (value === '' ? undefined : value),
      z.enum([
        'categoryAlcoholCard',
        'categoryBeverageCard',
        'categoryBilards',
        'categoryBowlingLanes',
        'categoryBreakfasts',
        'categoryDesserts',
        'categoryEscapeRooms',
        'categoryGames',
        'categoryLunch',
        'categoryMenu',
        'categoryPartiesAndEvents',
        'categoryPrivateLodges',
        'categorySnacks',
        'categoryVipZone',
        'categoryOther',
      ]),
    )
    .refine((value) => !!value, {
      message: t('errorMessages.thisFieldIsRequired'),
    }),
  image: z.union([z.instanceof(File), z.string(), z.null()]).nullable(),
});
