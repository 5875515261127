import { useEffect, useState } from 'react';

const useIsBreakpoint = (breakpoint: number) => {
  const [isBreakpoint, setIsBreakpoint] = useState<boolean>(false);

  const handleWindowSizeChange = () => {
    setIsBreakpoint(window.innerWidth <= breakpoint);
  };

  useEffect(() => {
    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isBreakpoint;
};

export default useIsBreakpoint;
