import { z } from 'zod';

export const DayConfigSchema = z.object({
  open: z.string(),
  close: z.string(),
});

export const OpeningHoursSchema = z
  .object({
    monday: DayConfigSchema.optional(),
    tuesday: DayConfigSchema.optional(),
    wednesday: DayConfigSchema.optional(),
    thursday: DayConfigSchema.optional(),
    friday: DayConfigSchema.optional(),
    saturday: DayConfigSchema.optional(),
    sunday: DayConfigSchema.optional(),
  })
  .nullable();

export const GetPlaceFirstStepsFormValues = z.object({
  data: z.object({
    logo: z.string().nullable(),
    avgReservationTime: z.number(),
    category: z.string(),
    description: z.string().nullable(),
    encodedName: z.string(),
    isChatAvailable: z.boolean(),
    maxReservationTime: z.number(),
    minReservationTime: z.number(),
    name: z.string(),
    openingHours: OpeningHoursSchema,
    phoneNumber: z.string(),
    priceRange: z.string().nullable(),
    slogan: z.string().nullable(),
    slotInterval: z.number(),
    subcategoriesIds: z.array(z.string()),
    timezone: z.string(),
    websiteUrl: z.string(),
  }),
  successCode: z.string(),
});
